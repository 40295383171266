import { ErrorsType } from 'interfaces';
import {
  useSigninMutation,
  useSigninSendMfaMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

// confirm methods valid for safety's sake
const validateMethods = (
  availableMfaMethods: string[],
  preferredMfaMethod: string
) => {
  const singleValidMethod =
    availableMfaMethods.length === 1 &&
    (availableMfaMethods.includes('email') ||
      availableMfaMethods.includes('phone'));
  const doubleValidMethod =
    availableMfaMethods.length === 2 &&
    availableMfaMethods.includes('email') &&
    availableMfaMethods.includes('phone');
  const validPreferred =
    (preferredMfaMethod === 'email' || preferredMfaMethod === 'phone') &&
    availableMfaMethods.includes(preferredMfaMethod);

  if (validPreferred && (singleValidMethod || doubleValidMethod)) {
    return {
      methodsValid: true,
      available: availableMfaMethods,
      preferred: preferredMfaMethod,
    };
  }

  return { methodsValid: false, available: [], preferred: '' };
};

export const useSignIn = () => {
  const [errors, setErrors] = useState<ErrorsType>(null);
  const [signIn, { loading: signInLoading }] = useSigninMutation();
  const [sendMfa, { loading: sendMfaLoading }] = useSigninSendMfaMutation();
  const {
    login,
    state: { loading: loginLoading },
  } = useAuth();

  const router = useRouter();

  const resetErrors = (code: number) =>
    setErrors((errors) => errors?.filter((error) => error.code !== code));

  const onSubmit = useCallback(
    async (values) => {
      try {
        const result = await signIn({
          variables: { data: values },
        });

        if (result.data?.login.success) {
          setErrors(null);

          if (result.data?.login.userId) {
            // userId present if user recently completed onboarding, skip mfa & go straight to login
            await login({ userId: result.data.login.userId });
          } else {
            const { methodsValid, available, preferred } = validateMethods(
              result.data.login.availableMfaMethods || [],
              result.data.login.preferredMfaMethod || ''
            );

            if (methodsValid) {
              if (available.length === 1) {
                // only one mfa method, send via preferred method and go to input screen
                const mfaResult = await sendMfa({
                  variables: { data: { method: preferred } },
                });

                if (mfaResult.data?.authSendMfaCode.success) {
                  router.push({
                    pathname: '/auth/2-fa',
                    query: {
                      from: '/',
                      method: preferred,
                    },
                  });
                } else {
                  const errors = mfaResult.data?.authSendMfaCode.errors;
                  setErrors(errors);
                  setSentryErrors(errors);
                }
              } else {
                // multiple mfa methods, go to mfa method selection screen
                router.push({
                  pathname: '/auth/send-2-fa',
                  query: {
                    from: '/',
                    available: available.join(','),
                    preferred: preferred,
                  },
                });
              }
            } else {
              // matches backend error used by web_login if user missing available/preferred methods
              const errors = [
                {
                  code: 222,
                  message:
                    'Error 222 \n Available and preferred MFA methods not set. Please complete onboarding or contact administrator.',
                  severity: 1,
                },
              ];
              setErrors(errors);
              setSentryErrors(errors);
            }
          }
        } else {
          const errors = result.data?.login.errors;
          setErrors(errors);
          setSentryErrors(errors, { userId: result.data?.login.userId || '' });
        }
      } catch (e) {
        const formattedError = formatError(e);
        setErrors([formattedError]);
        setSentryErrors(e);
      }
    },
    [signIn, sendMfa, login, router]
  );

  return {
    loading: Boolean(signInLoading || loginLoading || sendMfaLoading),
    errors,
    onSubmit,
    resetErrors,
  };
};
