import AuthPages from 'modules/auth/containers/AuthPages';
import { useSignIn } from 'modules/auth/containers/AuthPages/hooks/useSignIn';
import theme from 'stories/theme';
import Layout from 'ui/LayoutV2';
import SEO from 'ui/SEO';
import { withGetServerSideProps } from 'utils/hooks/useAuthRouter';

export const getServerSideProps = withGetServerSideProps();

export default function SigninPage() {
  const { loading, errors, resetErrors, onSubmit } = useSignIn();

  return (
    <Layout
      headerVariant={3}
      footerVariant={2}
      footerParams={{ backgroundColor: theme.colors.grey[700] }}
      customStyles={{
        backgroundColor: theme.colors.grey[700],
        children: {
          paddingBottom: 0,
          skipFooterHeight: {
            value: true,
            device: 'mobile',
          },
        },
      }}
    >
      <SEO title="Sign in" />
      <AuthPages
        pageName="signin"
        loading={loading}
        errors={errors}
        resetErrors={resetErrors}
        onSubmit={onSubmit}
      />
    </Layout>
  );
}
